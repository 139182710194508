$input_height: 50px;
$input_back_color: #fff;
$input_text_color: #000;
$input_text_size: 20px;
$color-text-gris: #aaaaaa;
$color-red: #c10023;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: 18px;

$input_border_color: $color-red;
$input_border_radius: 0;
$input_border_size: 1px;
$input_border_style: solid;

$input_margin: 10px 0;
$text_color: #fff;
$label_color: #fff;
$label_checkbox_border_color: $label-color;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: white;
$message_errors_border_color: hsla(0, 100%, 55%, 1);
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: white;
$message_success_border_color: hsla(120, 100%, 50%, 1);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: hsla(120, 100%, 50%, 0.5);

form {
    input.form-control, textarea.form-control {
        height: $input_height;
        color: $input_text_color;
        font-size: $input_text_size;
        background-color: $input_back_color;
        border: $input_border_size $input_border_style $input_border_color;
        border: 0;
        border-radius: 1px;
        border: 1px solid #616161;
        margin-top: 5px;
    }
    input::placeholder, textarea::placeholder {
        font-size: $input_placeholder_size;
        color: $input_placeholder_color;
    }
    .title {
        text-transform: uppercase;
        color: $text_color;
        font-weight: bold;
        margin-top: 1em;
    }
    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .radios, .checkboxes {
        width: 100%;
        margin: 20px 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input[type="radio"], input[type="checkbox"] {
            position: absolute;
            visibility: hidden;
            &:checked+label:before {
                background-color: $input_checked_color;
            }
        }
        input[type="radio"] {
            &+label:before {
                border-radius: 100%;
            }
        }
        label {
            display: inline-block;
            cursor: pointer;
            color: $label_color;
            position: relative;
            padding: 6px 5px 6px 35px;
            font-size: $input_text_size;
            transition: all .3s ease;
            &:before {
                content: "";
                display: inline-block;
                width: $input_text_size;
                height: $input_text_size;
                position: absolute;
                left: 0px;
                background: none;
                border: 2px solid $label_checkbox_border_color;
            }
        }
    }
    .radios {
        justify-content: space-between;
    }
    textarea {
        margin: 2em 0;
        height: $textarea_height !important;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        margin: $input_height 0;
        label.button, button#submit {
            border: $input_border_size $input_border_style $input_border_color;
            border-radius: $input_border_radius;
            border: 0;
        }
    }

    label.top-input {
        color: #000;
        font-size: 18px;
        font-weight: 500;
    }

    div.content-recaptcha {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    div.content-content-boutons {
        padding: 0;
        margin: 0;
        margin-top: 25px;
        div.content-boutons {
            padding: 0;
            margin: 0;
            @media screen and (max-width: 575px) {                
                align-items: center;
                flex-direction: column;
                justify-content: center;
            }
        }

        div.content-btn-submit {
            @media screen and (max-width: 575px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }            
        }        
    }

    div.col-xs-12.col-sm-4.form-group  {
        @media screen and (max-width: 480px) {
            //padding: 0;
        }
    }

    #submit {
        margin-top: 15px;
        border: 0;
        background: #000;
        color: #fce903;
        font-size: 22px;
        padding: 20px;
        width: 25vw;
        min-width: 240px;
        opacity: 1;
        transition: .5s;
        position: relative;
        top: 22px;
    }

    #submit:hover {
        background: rgb(73, 73, 73);
        transition: .5s;
    }

    div.contenet-select-file {
        display: flex;
        flex-direction: column;
        align-items: center;
        label.top-input-1 {
            color: #000;
            font-size: 18px;
            font-weight: 500;
        }

        label.top-input-2 {            
            border: 0;            
            opacity: 1;
            width: 20vw;  
            min-width: 240px;          
            color: #fce903;
            padding: 15px 25px;
            margin-top: 15px;
            font-size: 22px;
            padding: 25px;
            width: 25vw;
            background: #000;
            text-align: center;
            text-transform: uppercase;
            font-weight: 400;
            cursor: pointer;
            transition: .5s;
        }
        label.top-input-2:hover {
            background: rgb(73, 73, 73);
            transition: .5s;
        }
    }
}
.messages {
    padding: 30px;
    margin-bottom: 2em;
    &.errors, &.success {
        p {
            font-size: 24px;
            line-height: 1;
            margin: 0
        }
    }
    &.errors {
        background-color: $message_errors_background_color;
        border-radius: $message_errors_border_radius;
        border: $message_errors_border_size solid $message_errors_border_color;
        color: $message_errors_text_color;
        p {
            color: $message_errors_text_color;
        }
    }
    &.success {
        background-color: $message_success_background_color;
        border-radius: $message_success_border_radius;
        border: $message_success_border_size solid $message_success_border_color;
        color: $message_success_text_color;
        p {
            color: $message_success_text_color;
        }
    }
}

div.help-block li {
    position: absolute;
    margin-top: -17px;
}
