// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';

/* purgecss start ignore */

 @import 'addons/font-awesome/font-awesome';
// @import 'addons/owl-carousel2/owl.carousel';
@import 'addons/layerslider/layerslider';
// @import 'addons/magnific-popup/magnific-popup';
// @import 'addons/cube-portfolio/cubeportfolio';
// @import 'addons/parallax-scroll/normalize';
// @import 'addons/parallax-scroll/main';
@import '_alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'reset';
@import 'variables';
@import 'functions';

/* GENERAL TEMPLATE */

body {
    background-color: $bg-color-body;

    h1.referencement {
        display: none !important;
    }
    .appear {
        opacity: 0;
        @include transition(opacity 0.75s ease-in);
    }
    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

section.section-header.residentiel {
    div.module-header {
        div.caption {            
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 9;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            height: 49vw;
            @media screen and (max-width: 767px) {
                height: 120vw;
                transition: .5s;
                @media screen and (max-width: 480px) {
                    height: 150vw;
                }
            }
            h3 {
                color: #FFF;
                font-size: 50px;
                font-weight: 400;
                text-shadow: 0px 2px 2px rgb(0, 0, 0);
            }
        }
    }
}

section.section-text-img-residentiel {
    padding: 75px 15px;
    background: #FFF;
    div.container-fluid {
        display: flex;
        justify-content: space-between;
        padding: 0 5vw;
        //align-items: center;
        transition: .5s;
        @media screen and (max-width: 1199px) {
            padding: 0 0;
            transition: .5s;
            @media screen and (max-width: 767px) {
                flex-direction: column;
                transition: .5s;
            }
        }
        div.left-side.img-content {
            width: 40%;      
            transition: .5s; 
            @media screen and (max-width: 767px) {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .5s;
            }     
        }
        div.rith-side.text-content {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: 767px) {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                transition: .5s;
            } 
            div.top-yellow-box {
                padding: 25px 30px;
                background: #fbe903;
                margin-bottom: 35px;
                transition: .5s;
                @media screen and (max-width: 1430px) {
                    margin-bottom: 10px;
                    transition: .5s;
                    @media screen and (max-width: 1199px) {
                        margin-top: 0;
                        transition: .5s;
                        @media screen and (max-width: 991px) {
                            padding: 12px 30px;
                            transition: .5s;
                            @media screen and (max-width: 767px) {
                                padding: 25px 30px;
                                transition: .5s;
                            }
                        }
                    }
                }
                h3 {
                    text-transform: none;
                    font-weight: 400;
                    font-size: 30px;
                    font-family: $font-family-1;
                    transition: .5s;
                    @media screen and (max-width: 1150px) {
                        font-size: 24px;
                        transition: .5s;
                        @media screen and (max-width: 767px) {
                            font-size: 2.222rem;
                            transition: .5s;
                            @media screen and (max-width: 480px) {
                                text-align: center;
                            }
                        }
                    }
                }
            }            
            p {
                color: #000;
                font-weight: 400;
                font-size: 24px;
                margin-top: 10px;
                padding: 15px;
                font-family: $font-family-1;
                transition: .5s;
                @media screen and (max-width: 1724px) {
                    font-size: 1.2rem;
                    line-height: 30px;
                    transition: .5s;
                    @media screen and (max-width: 1430px) {
                        margin-bottom: 0px;
                        transition: .5s;
                        @media screen and (max-width: 1199px) {
                            margin-top: 0;
                            transition: .5s;
                            @media screen and (max-width: 991px) {
                                line-height: 22px;
                                transition: .5s;
                                @media screen and (max-width: 767px) {
                                    font-size: 1.5rem;
                                    line-height: 28px;
                                    transition: .5s;
                                    padding-left: 0;
                                    padding-right: 0;
                                    @media screen and (max-width: 480px) {
                                        text-align: center;
                                    }
                                } 
                            }
                        }
                    }
                }
            }
            ul.list-services-residentiel {
                padding-left: 40px;
                transition: .5s;
                li.item-list {
                    text-transform: none;
                    color: #000;
                    font-size: 1.2rem;
                    transition: .5s;
                    @media screen and (max-width: 1430px) {
                        line-height: 35px;
                        font-size: 1.2rem;
                        transition: .5s;
                        @media screen and (max-width: 991px) {
                            line-height: 22px;
                            transition: .5s;
                            @media screen and (max-width: 767px) {
                                font-size: 1.5rem;
                                line-height: 35px;
                                transition: .5s;
                                @media screen and (max-width: 480px) {
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }        
    }

    div.container-fluid.content-bouton {
        margin-top: 35px;
        @media screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        div.col-md-custom.left-btn-content {
            width: 42%;
            @media screen and (max-width: 767px) {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 25px;
                width: 100%;
            }
            div.content-btn {                
                //height: 85px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px;
                background: #fbe903;
                margin-left: 10px;
                min-width: 612px;
                transition: .5s;
                width: 86%;
                @media screen and (max-width: 1724px) {
                    min-width: 35.222vw;
                    @media screen and (max-width: 1199px) {
                        min-width: 38.666vw;
                        transition: .5s;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            margin-left: 0;
                            transition: .5s;
                        }
                    }
                }
                a {
                    color: #000;
                    padding: 15px 25px;
                    background: #fbe903;
                    border: 3px solid #FFF;
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 28px;
                    font-weight: 600;
                    width: 100%;
                    transition: .5s;
                    @media screen and (max-width: 991px) {
                        font-size: 1.2rem;
                        transition: .5s;
                        @media screen and (max-width: 767px) {
                            font-size: 1.5rem;
                            transition: .5s;
                        }
                    }
                    small {
                        font-size: 18px;
                        font-weight: 500;
                        @media screen and (max-width: 991px) {
                            font-size: .9rem;
                            transition: .5s;
                            @media screen and (max-width: 767px) {
                                font-size: 1.222rem;
                                transition: .5s;
                            }
                        }
                    }
                }
                a:hover {
                    background: #aca000;
                    transition: .5s;
                }
            }
            div.content-btn:hover {
                background: #aca000;
                transition: .5s;
            }
        }
        div.col-md-custom.right-btn-content {
            width: 50%;
            @media screen and (max-width: 767px) {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
            div.content-btn {                
                //height: 85px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px;
                background: #fbe903;
                min-width: 612px;
                transition: .5s;
                width: 72%;
                @media screen and (max-width: 767px) {
                    min-width: 38.666vw;
                    width: 100%;
                    transition: .5s;
                }
                a {
                    color: #000;
                    padding: 15px 25px;
                    background: #fbe903;
                    border: 3px solid #FFF;
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 28px;
                    font-weight: 600;
                    transition: .5s;
                    width: 100%;
                    @media screen and (max-width: 991px) {
                        font-size: 1.2rem;
                        transition: .5s;
                        @media screen and (max-width: 767px) {
                            font-size: 1.5rem;
                            transition: .5s;
                        }
                    }
                    small {
                        font-size: 18px;
                        font-weight: 500;
                        @media screen and (max-width: 991px) {
                            font-size: .9rem;
                            transition: .5s;
                            @media screen and (max-width: 767px) {
                                font-size: 1.222rem;
                                transition: .5s;
                            }
                        }
                    }
                }
                a:hover {
                    background: #aca000;
                    transition: .5s;
                }
            }
            div.content-btn:hover {
                background: #aca000;
                transition: .5s;
            }
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
  content:"";
  display:block;
  height:$size-nav-height; /* fixed header height*/
  margin:-$size-nav-height 0 0; /* negative fixed header height */
}
.shadow {
	position: relative;
	&::after {
		content: '';
		z-index: 1;
		width: 100%;
		top: 100%;
		height: 40px;
		position: absolute;
		background: linear-gradient(to bottom , rgba(0 ,0 ,0 ,0.5), rgba(0 ,0 ,0 ,0) );
	}
}

div.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

div.row {
    margin-left: 0!important;
    margin-right: 0!important;
}

div.bar-shadow {
    z-index: 1;
    width: 100%;
    top: -40px;
    height: 40px;
    position: relative;        
    -webkit-box-shadow: 0px 10px 28px 0px rgba(50, 50, 50, 0.86);
    -moz-box-shadow:    0px 10px 28px 0px rgba(50, 50, 50, 0.86);
    box-shadow:         0px 10px 28px 0px rgba(50, 50, 50, 0.86);
}

div.section-icon-bursts {
    background: url(../images/parallax_bg.jpg) center center;
    background-attachment: fixed;
    background-size: cover;
    margin-top: -50px;
    min-height: 300px;
    z-index: 9;
    position: relative;
    div.row {
        div.container-fluid {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px;
            padding-top: 40px;
            div.text-bloc {
                display: flex;
                justify-content: center;
                width: 26.33333%;
                min-width: 304px;
                transition: 0.5s; 
            }

            div.text-bloc:nth-child(1) {
                a.individual-burst {
                    width: 350px;
                    min-width: 255px;
                    height: 245px;                    
                    padding-right: 10px;
                    border: 3px solid #FFF;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    transition: .5s;
                    cursor: pointer;
                    @media screen and (max-width: 991px) {
                        max-width: 220px;
                    }
                    div.img-content {
                        width: 45%;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        transition: .5s;
                        img {
                            max-width: 150px;
                            width: 150px;
                            transition: .5s;
                        }
                        img:nth-child(2) {
                            opacity: 0;
                        }
                        img:nth-child(1) {
                            position: absolute;
                            opacity: 1;
                        }
                    }
                    label {
                        color: #FFF;
                        text-transform: uppercase;
                        flex-wrap: wrap;
                        line-height: 30px;
                        font-weight: 400;
                        font-size: 22px;
                        width: 55%;
                        cursor: pointer;
                        transition: .5s;
                    }
                }
            }

            div.text-bloc:nth-child(1):hover {
                a.individual-burst {
                    div.img-content {
                        img:nth-child(2) {
                            opacity: 1;
                            transition: .5s;
                        }
                        img:nth-child(1) {
                            opacity: 0;
                            transition: .5s;
                        }
                    }
                }
            }
            
            div.text-bloc:nth-child(3) {
                a.individual-burst {
                    width: 350px;
                    min-width: 255px;
                    height: 245px;
                    padding-right: 10px;
                    border: 3px solid #FFF;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    @media screen and (max-width: 991px) {
                        max-width: 220px;
                    }
                    div.img-content {
                        width: 45%;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        transition: .5s;
                        img {
                            max-width: 150px;
                            width: 150px;
                            transition: .5s;
                        }
                        img:nth-child(2) {
                            opacity: 0;
                        }
                        img:nth-child(1) {
                            position: absolute;
                            opacity: 1;
                        }
                    }
                    label {
                        color: #FFF;
                        text-transform: uppercase;
                        flex-wrap: wrap;
                        line-height: 30px;
                        font-weight: 400;
                        font-size: 22px;
                        width: 55%;
                        cursor: pointer;
                        transition: .5s;
                    }
                }                
            }
            div.text-bloc:nth-child(3):hover {
                a.individual-burst {
                    div.img-content {
                        img:nth-child(2) {
                            opacity: 1;
                            transition: .5s;
                        }
                        img:nth-child(1) {
                            opacity: 0;
                            transition: .5s;
                        }
                    }
                }
            }

            div.text-bloc:nth-child(2) {
                a.individual-burst {
                    width: 350px;
                    min-width: 255px;
                    height: 245px;
                    max-width: 220px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 100%;
                        height: 100%;
                        max-width: 250px;
                        @media screen and (max-width: 991px) {
                            max-width: 100%;
                            height: 250px;
                        }
                    }
                }
                @media screen and (max-width: 991px) {
                    margin-top: 25px;
                }
            }

            div.text-bloc:hover {
                transition: 0.5s;
                a.individual-burst {
                    .text-down {
                        color: #fbe903;
                        transition: 0.5s;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    div.section-icon-bursts {
        background-repeat: repeat;
        div.container-fluid {
            flex-direction: column;
            padding-top: 25px;
            padding-bottom: 25px;
            div.text-bloc {
                margin-bottom: 25px;
                p {
                    label.text-down {
                        font-size: 22px;
                    }
                }
            }
            div.text-bloc:nth-last-child(1) {
                margin-bottom: 0;
                @media screen and (max-width: 991px) {
                    margin-top: 25px;
                }
            }    
        }
    }
}

div.section-03-bursts {
    background: url(../images/section_03_bg.jpg) no-repeat center center;
    background-size: cover;
    padding-bottom: 7vw;
    div.row {
        div.container-fluid {
            div.text-bloc {
                display: flex;
                padding-top: 25px;
                h2 {
                    color: #fbe903;
                    text-transform: uppercase;
                    transform: translate(30px, 20px) rotate(180deg);
                    writing-mode: vertical-rl;
                    letter-spacing: 20px;
                    text-align: right;
                    line-height: 75px;
                    width: 75px;
                    font-size: 40px;
                    font-weight: 500;
                    text-shadow: 0px -1px 8px #000;
                }
                div.list-and-logo {
                    width: 100%;
                    padding-left: 75px;
                    margin-top: 22px;
                    ul.list-specialitees {
                        width: 100%;
                        list-style: none;
                        list-style-type: none;
                        li.item-list {
                            text-transform: none;
                            color: #FFF;
                            font-size: 20px;
                            font-weight: 500;
                            border-bottom: 1px solid #fbe903;
                            text-shadow: 0px -1px 8px #000;
                            line-height: 50px;
                        }
                    }
                    div.urgence-logo {
                        width: 400px;
                        background: #fbe903;
                        padding: 10px;
                        margin: 25px;
                        margin-top: 50px;
                        cursor: pointer;
                        transition: .5s;
                        @media screen and (max-width: 1256px) {
                            width: 300px;
                        }
                        div.content-logo {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px;
                            border: 3px solid #FFF;
                            @media screen and (max-width: 1202px) {
                                padding-right: 0px;                                
                            }
                            img {
                                margin-right: 30px;
                                @media screen and (max-width: 1256px) {
                                    width: 100px;
                                    margin: 0;
                                    right: 5px;
                                    @media screen and (max-width: 480px) {
                                        width: 75px;
                                    }
                                }
                            }
                            
                            img:nth-child(1) {
                                //display: ;
                                position: relative;
                            }
                            img:nth-child(2) {
                                //display: ;
                                position: relative;
                            }
                            img.img-2 {
                                position: absolute;
                                padding-right: 128px;
                                opacity: 0;
                                transition: .5s;
                                @media screen and (max-width: 1299px) {
                                    padding-right: 118px;
                                    @media screen and (max-width: 991px) {
                                        padding-right: 130px;
                                    }
                                }
                            }
                            
                            h3 {
                                font-weight: 500;
                                line-height: 28px;
                                @media screen and (max-width: 1202px) {
                                    text-align: center;
                                }
                            }
                        }
                        div.content-logo:hover {
                            img.img-2 {
                                opacity: 1;
                                transition: .5s;
                            }                       
                        }
                    }
                    div.urgence-logo:hover {
                        background: #bbae00;
                        transition: .5s;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    div.section-03-bursts {
        div.row {
            div.container-fluid {
                div.text-bloc {
                    h2 {
                        text-align: right;
                        line-height: 25px;
                        width: 55px;
                        font-size: 25px;
                    }                    
                    div.list-and-logo {
                        padding-left: 30px;
                        margin-top: 0;
                        div.urgence-logo {
                            div.content-logo h3 {
                                font-size: 22px;
                            }                            
                        }

                        ul.list-specialitees {
                            li.item-list {
                                line-height: 35px;
                            }
                        }
                    }
                }
            }
        }
    }      
}

@media screen and (max-width: 480px) {
    div.section-03-bursts {
        div.row {
            div.container-fluid {
                div.text-bloc {
                    h2 {
                        width: 55px;
                        font-size: 22px;
                        position: relative;
                        left: -28px;
                        top: -30px;
                    }                    
                    div.list-and-logo {
                        padding-left: 0;
                        margin-top: 0;                        
                        div.urgence-logo {
                            display: flex;
                            flex-direction: column;
                            div.content-logo h3 {
                                font-size: 15px;
                                line-height: 15px;
                            }                            
                        }

                        ul.list-specialitees {
                            li.item-list {
                                line-height: 35px;
                                font-size: 15px;
                                padding-right: 20px;
                            }
                        }

                        div.urgence-logo {
                            width: 230px;
                            img {                                
                                width: 55px;
                                margin-right: 12px;
                            }
                            h3 {
                                font-size: 18px;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }
        }
    }      
}

div.section-04-icon-bursts {
    background: url(../images/parallax_bg.jpg) center center;
    //background-attachment: fixed;
    //background-size: contain;
    padding-top: 25px;
    padding-bottom: 35px;
    div.row {
        div.container-fluid {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            div.top-section-04 {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                h3 {
                    color: #fbe903;
                    font-weight: 400;
                    line-height: 32px;
                    text-align: center;
                }

                div.content-three-logos {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    margin-top: 25px;
                    margin-bottom: 25px;
                    width: 50%;
                    border-top: 2px solid #FFF;
                    border-bottom: 2px solid #FFF;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    @media screen and (max-width: 991px) {
                        width: 95%;
                        @media screen and (max-width: 480px) {
                            img {
                                width: 92px;
                            }
                        }
                    }
                }

                h4 {
                    color: #FFF;
                    font-weight: 400;
                    line-height: 32px;
                    text-align: center;
                    border-top: 2px solid #FFF;
                    border-bottom: 2px solid #FFF;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin-top: 25px;
                    margin-bottom: 25px;
                    text-transform: uppercase;
                }
            }
            div.list-logo-icons {
                display: flex;
                justify-content: center;
                align-items: center;  
                width: 100%;
                margin-top: 20px;
                margin-bottom: 40px;
                div.logo-bloc {
                    flex:1 1 auto;
                }
                img {
                    
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    div.section-04-icon-bursts {
        div.row {
            div.container-fluid {
                div.top-section-04 {
                    h3 {
                        font-size: 22px;
                    }
                }
                div.list-logo-icons {
                    
                }
            }
        }
    }
}

@media screen and (max-width: 678px) {
    div.section-04-icon-bursts {
        background-repeat: repeat;
        div.row {
            div.container-fluid {
                div.top-section-04 {
                    h3 {
                        
                    }
                }
                div.list-logo-icons {
                    flex-direction: column;
                    div.logo-bloc {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

main.commercial-page {
    section.section-text-img-residentiel {
        padding: 75px 0px;
        div.container-fluid {
            padding-left: 0;
            justify-content: end;
            padding: 0 15px;
            div.left-side.img-content {
                margin-right: 5vw;                
            }
            div.rith-side.text-content {                
                div.top-yellow-box {
                    margin-bottom: 0;
                    h3 {
                        width: 80%;
                        @media screen and (max-width: 1450px) {
                            font-size: 1.5rem;
                            @media screen and (max-width: 991px) {
                                width: 100%;
                                font-size: 1.7rem;
                            }
                        }
                    }
                }                
                p {
                    line-height: 35px;
                }
                ul.list-services-residentiel {
                    li.item-list {
                        line-height: 40px;
                        @media screen and (max-width: 991px) {
                            font-size: 1.5rem;
                            text-align: left;
                        }
                    }
                }

                ul.list-services-residentiel.box-1 {
                    margin-bottom: 55px;
                }

                ul.list-services-residentiel.box-2 {
                    margin-top: 55px;
                }
            }
        }
        div.container-fluid.content-bouton {
            justify-content: center;
            align-items: center;
            div.col-md-custom.right-btn-content {
                div.content-btn {
                    min-width: auto;                    
                }
            }
        }
    }
}

main.automobile {
    section.section-text-img-automobile {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 75px 0;
        div.container-fluid.part-1 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            @media screen and (max-width: 767px) {
                flex-direction: column;
            }
            div.left-side.content-img {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            div.right-side.content-text {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: end;
                flex-direction: column;  
                @media screen and (max-width: 915px) {
                    width: 75%;
                    @media screen and (max-width: 767px) {
                        width: 97%;
                    }
                }
                div.content-title-top {
                    padding: 15px 30px;
                    background: #fbe903;
                    margin-bottom: 35px;
                    transition: .5s;
                    width: 90%;
                    margin-bottom: 15px;
                    transition: .5s;                    
                    @media screen and (max-width: 1430px) {
                        margin-bottom: 10px;
                        @media screen and (max-width: 1199px) {
                            margin-top: 0;
                            @media screen and (max-width: 991px) {
                                padding: 12px 30px;
                                @media screen and (max-width: 767px) {
                                    padding: 20px 25px;     
                                    width: 100%;
                                    @media screen and (max-width: 480px) {
                                        padding: 15px 5px;
                                    }
                                }
                            }
                        }
                    }
                    h3 {
                        text-transform: none;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 42px;
                        font-family: $font-family-1;
                        display: table-cell;
                        transition: .5s;
                        @media screen and (max-width: 1575px) {
                            font-size: 1.8vw;
                            line-height: 32px;
                            display: table-cell;
                            white-space: nowrap;
                            @media screen and (max-width: 1150px) {
                                font-size: 24px;
                                transition: .5s;                                
                                @media screen and (max-width: 767px) {
                                    font-size: 2.222rem;
                                    transition: .5s;
                                    @media screen and (max-width: 480px) {
                                        text-align: center;
                                        font-size: 1.5rem;
                                        font-weight: 600;
                                        padding: 0;
                                        margin: 0;
                                    }
                                }
                            }
                        }                        
                    }
                }                
                ul.content-list-ser-auto {
                    padding-left: 55px;
                    transition: .5s;
                    @media screen and (max-width: 1575px) {
                        padding-left: 24px;
                    }
                    li.item-list {
                        text-transform: none;
                        color: #000;
                        font-size: 26px;
                        line-height: 35px;
                        font-weight: 500;
                        margin-bottom: 40px;
                        transition: .5s;
                        @media screen and (max-width: 1575px) {
                            font-size: 1.5vw;
                            margin-bottom: 15px;
                            @media screen and (max-width: 1250px) {
                                font-size: 1.3rem;
                                margin-bottom: 1.2rem;
                                @media screen and (max-width: 767px) {
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }
                }
            }            
        }

        div.container-fluid.part-2 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            @media screen and (max-width: 767px) {
                flex-direction: column;
            }
            div.left-side.content-img {
                width: 50%;
                display: flex;
                justify-content: end;
                align-items: end;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
            div.right-side.content-text {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: end;
                flex-direction: column;
                @media screen and (max-width: 915px) {
                    width: 75%;
                    @media screen and (max-width: 767px) {
                        width: 96%;
                    }
                }
                p {
                    color: #000;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 35px;
                    margin-bottom: 37px;
                    padding-left: 25px;
                    width: 90%;
                    @media screen and (max-width: 1250px) {
                        font-size: 1.2rem;
                        margin-bottom: 1.2rem;
                        padding-left: 0;
                        @media screen and (max-width: 767px) {
                            font-size: 1.8rem;
                            width: 100%;
                            @media screen and (max-width: 767px) {
                                font-size: 1.5rem;
                                text-align: center;
                            }
                        }
                    }
                }
                div.content-title-top {
                    padding: 15px 30px;
                    background: #fbe903;
                    margin-bottom: 35px;
                    transition: .5s;
                    width: 90%;
                    margin-bottom: 15px;
                    transition: .5s;                    
                    @media screen and (max-width: 1430px) {
                        margin-bottom: 10px;
                        @media screen and (max-width: 1199px) {
                            margin-top: 0;
                            @media screen and (max-width: 991px) {
                                padding: 12px 30px;
                                @media screen and (max-width: 767px) {
                                    padding: 25px 30px;
                                    width: 100%;
                                    @media screen and (max-width: 480px) {
                                        padding: 15px 5px;
                                    }
                                }
                            }
                        }
                    }
                    h3 {
                        text-transform: none;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 42px;
                        font-family: $font-family-1;
                        transition: .5s;
                        @media screen and (max-width: 1575px) {
                            font-size: 1.8vw;
                            line-height: 32px;
                        }
                        @media screen and (max-width: 1150px) {
                            font-size: 24px;
                            transition: .5s;
                            @media screen and (max-width: 767px) {
                                font-size: 2.222rem;
                                transition: .5s;
                                @media screen and (max-width: 480px) {
                                    text-align: center;
                                    font-weight: 600;
                                    padding: 0;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }                
                ul.content-list-ser-auto {
                    padding-left: 55px;
                    transition: .5s;
                    @media screen and (max-width: 1575px) {
                        padding-left: 24px;
                    }
                    li.item-list {
                        text-transform: none;
                        color: #000;
                        font-size: 26px;
                        line-height: 35px;
                        font-weight: 500;
                        margin-bottom: 40px;
                        transition: .5s;
                        @media screen and (max-width: 1575px) {
                            font-size: 1.5vw;
                            margin-bottom: 15px;
                            @media screen and (max-width: 1250px) {
                                font-size: 1.3rem;
                                margin-bottom: 1.2rem;
                                @media screen and (max-width: 767px) {
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }
                }
                label {
                    text-transform: none;
                    color: #000;
                    font-size: 26px;
                    line-height: 35px;
                    font-weight: 500;
                    margin-bottom: 10px;
                    @media screen and (max-width: 1575px) {
                        font-size: 1.5vw;
                        margin-bottom: 15px;
                        @media screen and (max-width: 1250px) {
                            font-size: 1.3rem;
                            margin-bottom: 1.2rem;
                        }
                    }
                }
                div.content-btn-bottom {
                    padding: 15px;
                    height: 125px;
                    display: flex;
                    justify-content: center;
                    background: #fbe903;
                    align-items: center;
                    width: 30vw;
                    a {
                        width: 100%;
                        height: 100%;
                        color: #000;
                        font-size: 34px;
                        font-weight: 500;
                        border: 4px solid #FFF;
                        text-transform: uppercase;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                    }
                }
            }            
        }
    }

    section.paralax-center-part {
        min-height: 320px;
        background: url(../images/parallax_bg.jpg) no-repeat center center;
        background-attachment: fixed;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: 1.5s;
        @media screen and (max-width: 991px) {
            min-height: 225px;
        }
        label {
            color: #FFF;
            font-size: 29px;
            font-weight: 500;
            margin-bottom: 32px;
            transition: .5s;
            @media screen and (max-width: 1575px) {
                font-size: 1.5vw;
                margin-bottom: 15px;
                @media screen and (max-width: 1250px) {
                    font-size: 1.3rem;
                    margin-bottom: 1.2rem;
                }
            }
        }
        div.content-btn-paralaxed {            
            padding: 15px;
            height: 125px;
            display: flex;
            min-width: 300px;
            justify-content: center;
            background: #fbe903;
            align-items: center;
            width: 30vw;
            transition: .5s;
            @media screen and (max-width: 1575px) {
                height: 105px;
                padding: 10px;
                transition: .5s;
                @media screen and (max-width: 1250px) {
                    height: 95px;
                    padding: 10px;
                }
            }
            a {
                width: 100%;
                height: 100%;
                color: #000;
                font-size: 34px;                
                font-weight: 500;
                border: 4px solid #FFF;
                text-transform: uppercase;
                justify-content: center;
                align-items: center;
                display: flex;
                transition: .5s;
                @media screen and (max-width: 1575px) {
                    font-size: 1.5vw;
                    @media screen and (max-width: 1250px) {
                        font-size: 1.3rem;
                        @media screen and (max-width: 767px) {
                            font-size: 1.9rem;
                        }
                    }
                }
            }
        }
        div.content-btn-paralaxed:hover {
            background: #aca000;
            transition: .5s;
            a {
                background: #aca000;
                transition: .5s;
            }
        }
    }
}

h3.sub-title {
    text-transform: none;
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    font-family: $font-family-1;
    display: table-cell;
    text-transform: uppercase;
    line-height: 55px;
    padding-left: 25px;
    margin-bottom: 25px;
    width: 100%;
    transition: .5s;
    @media screen and (max-width: 1575px) {
        font-size: 1.8vw;
        line-height: 32px;
        display: table-cell;
        white-space: nowrap;
        padding-left: 5px;
        @media screen and (max-width: 1150px) {
            font-size: 24px;
            transition: .5s;                                
            @media screen and (max-width: 767px) {
                text-align: center;
                font-size: 2.222rem;
                transition: .5s;
                @media screen and (max-width: 480px) {
                    font-size: 1.3rem;
                    font-weight: 600;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }                        
}

div.yellow-line {
    width: 100%;
    margin: 0 auto;
    margin-top: 55px;
    margin-bottom: 55px;
    //padding: 0px 5vw;
    //padding-left: 6.999vw;
    hr {
        width: 100%;
        border-bottom: 3px solid #fbe903;
        @media screen and (max-width: 480px) {
            width: 94%;
        }
    }
}

div.yellow-line-secure {
    width: 86%;
    margin: 0 auto;
    margin-top: 55px;
    margin-bottom: 55px;
    hr {
        width: 100%;
        border-bottom: 3px solid #fbe903;
        @media screen and (max-width: 480px) {
            width: 94%;
        }
    }
}

main.access-page {
    padding: 75px 15px;
    @media screen and (max-width: 767px) {
        padding: 75px 10px;
    }
    section.section-text-img-access {
        padding: 0 6vw;
        transition: .5s;
        @media screen and (max-width: 1555px) {
            padding: 0 2vw;
            @media screen and (max-width: 991px) {
                padding: 0 0;
            }
        }
        div.container-fluid.part-1 {
            display: flex;
            align-items: end;
            justify-content: center; 
            width: 100%;
            @media screen and (max-width: 767px) {
                flex-direction: column;
                align-items: center;
            }
            div.left-side.content-img {
                width: 50%;
                display: flex;
                justify-content: end;
                align-items: center;
                flex-direction: column;
                transition: .5s;
                @media screen and (max-width: 1655px) {
                    margin-right: 5vw;
                    transition: .5s;
                    @media screen and (max-width: 767px) {
                        width: 96%;
                        margin: 0;
                        justify-content: center;
                        align-items: center;                        
                        margin-bottom: 35px;
                    }
                }
            }
            div.right-side.content-text {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: end;
                flex-direction: column;
                @media screen and (max-width: 915px) {
                    width: 75%;
                    @media screen and (max-width: 767px) {
                        width: 97%;
                    }
                }
                div.content-title-top {
                    padding: 15px 30px;
                    background: #fbe903;
                    margin-bottom: 35px;
                    transition: .5s;
                    width: 100%;
                    margin-bottom: 15px;
                    transition: .5s;    
                    @media screen and (max-width: 1555px) {
                        width: 100%;
                        @media screen and (max-width: 1430px) {
                            margin-bottom: 10px;
                            @media screen and (max-width: 1199px) {
                                margin-top: 0;
                                @media screen and (max-width: 991px) {
                                    padding: 12px 30px;
                                    @media screen and (max-width: 767px) {
                                        padding: 20px 25px;     
                                        width: 100%;
                                        @media screen and (max-width: 480px) {
                                            padding: 15px 25px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                    h3 {
                        text-transform: none;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 42px;
                        font-family: $font-family-1;
                        display: table-cell;
                        transition: .5s;
                        @media screen and (max-width: 1575px) {
                            font-size: 1.8vw;
                            line-height: 32px;
                            display: table-cell;
                            white-space: nowrap;
                            @media screen and (max-width: 1150px) {
                                font-size: 24px;
                                transition: .5s;                                
                                @media screen and (max-width: 767px) {
                                    font-size: 2.222rem;
                                    transition: .5s;
                                    @media screen and (max-width: 480px) {
                                        text-align: center;
                                        font-size: 1.5rem;
                                        font-weight: 600;
                                        padding: 0;
                                        margin: 0;
                                    }
                                }
                            }
                        }                        
                    }
                }                
                ul.content-list-ser-auto {
                    padding-left: 55px;
                    transition: .5s;
                    @media screen and (max-width: 1575px) {
                        padding-left: 24px;
                    }
                    li.item-list {
                        text-transform: none;
                        color: #000;
                        font-size: 26px;
                        line-height: 35px;
                        font-weight: 500;
                        margin-bottom: 40px;
                        transition: .5s;
                        @media screen and (max-width: 1575px) {
                            font-size: 1.8vw;
                            margin-bottom: 15px;
                            @media screen and (max-width: 1250px) {
                                font-size: 1.3rem;
                                margin-bottom: 1.2rem;
                                @media screen and (max-width: 767px) {
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }
                }
                h4 {
                    color: #000;
                    font-size: 30px;
                    line-height: 45px;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    transition: .5s;
                    @media screen and (max-width: 1250px) {
                        font-size: 1.8rem;
                        line-height: 1.5rem;
                        padding-left: 10px;
                        @media screen and (max-width: 480px) {
                            text-align: center;
                            line-height: 25px;
                        }
                    }
                }
                p {
                    color: #000;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 35px;
                    margin-bottom: 37px;
                    //padding-left: 25px;
                    width: 95%;
                    padding-left: 25px;
                    @media screen and (max-width: 1250px) {
                        font-size: 1.8rem;
                        margin-bottom: 1.2rem;
                        padding-left: 10px;
                        width: 100%;
                        @media screen and (max-width: 767px) {
                            font-size: 1.8rem;
                            width: 100%;
                            @media screen and (max-width: 767px) {
                                font-size: 1.7rem;
                                text-align: center;
                                padding: 0;
                            }
                        }
                    }
                }
            }            
        }        
    }

    section.contact-bottom-part {
        display: flex;
        justify-content: center;
        align-items: center;
        div.content-btn-paralaxed {            
            padding: 15px;
            height: 125px;
            display: flex;
            min-width: 300px;
            justify-content: center;
            background: #fbe903;
            align-items: center;
            width: 30vw;
            transition: .5s;
            @media screen and (max-width: 1575px) {
                height: 105px;
                padding: 10px;
                transition: .5s;
                @media screen and (max-width: 1250px) {
                    height: 95px;
                    padding: 10px;
                }
            }
            a {
                width: 100%;
                height: 100%;
                color: #000;
                font-size: 34px;                
                font-weight: 500;
                border: 4px solid #FFF;
                text-transform: uppercase;
                justify-content: center;
                align-items: center;
                display: flex;
                transition: .5s;
                @media screen and (max-width: 1575px) {
                    font-size: 1.5vw;
                    @media screen and (max-width: 1250px) {
                        font-size: 1.3rem;
                        @media screen and (max-width: 767px) {
                            font-size: 1.9rem;
                        }
                    }
                }
            }
        }
        div.content-btn-paralaxed:hover {
            background: #aca000;
            transition: .5s;
            a {
                background: #aca000;
                transition: .5s;
            }
        }
    }
}

div.list-new-icons {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
}

main.security-page {    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 75px 15px;
    @media screen and (max-width: 767px) {
        padding: 75px 10px;
    }

    div.yellow-line {
        width: 100%;
        margin: 0 auto;
        margin-top: 55px;
        margin-bottom: 130px;
        hr {
            width: 100%;
            border-bottom: 3px solid #fbe903;
            @media screen and (max-width: 480px) {
                width: 94%;
            }
        }
    }
    section.section-text-img-security {
       width: 85%;
        @media screen and (max-width: 480px) {
            width: 96%;
        }
        div.container-fluid.part-1 {
            display: flex;
            align-items: end;
            justify-content: center; 
            width: 100%;
            @media screen and (max-width: 767px) {
                flex-direction: column;
                align-items: center;
            }
            div.left-side.content-img {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-right: 10vw;
                transition: .5s;
                @media screen and (max-width: 1655px) {
                    margin-right: 5vw;
                    transition: .5s;
                    @media screen and (max-width: 767px) {
                        width: 96%;
                        margin: 0;
                        justify-content: center;
                        align-items: center;                        
                        margin-bottom: 35px;
                        padding: 0;
                        img {
                            padding: 0;
                        }
                    }
                }
            }
            div.left-side.content-img.coloumn-img {
                flex-direction: column;
                img {
                    margin-bottom: 8vw;
                }
            }

            div.right-side.content-text {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: end;
                flex-direction: column;
                @media screen and (max-width: 915px) {
                    width: 75%;
                    @media screen and (max-width: 767px) {
                        width: 97%;
                    }
                }
                div.content-title-top {
                    padding: 10px 30px;
                    background: #fbe903;
                    margin-bottom: 35px;
                    transition: .5s;
                    width: 100%;
                    margin-bottom: 15px;
                    transition: .5s;    
                    @media screen and (max-width: 1555px) {
                        width: 100%;
                        @media screen and (max-width: 1430px) {
                            margin-bottom: 10px;
                            @media screen and (max-width: 1199px) {
                                margin-top: 0;
                                @media screen and (max-width: 991px) {
                                    padding: 12px 30px;
                                    @media screen and (max-width: 767px) {
                                        padding: 20px 25px;     
                                        width: 100%;     
                                        @media screen and (max-width: 480px) {
                                            padding: 15px 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                    h3 {
                        text-transform: none;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 42px;
                        font-family: $font-family-1;
                        display: table-cell;
                        transition: .5s;
                        @media screen and (max-width: 1575px) {
                            font-size: 1.8vw;
                            line-height: 32px;
                            display: table-cell;
                            //white-space: nowrap;
                            @media screen and (max-width: 1150px) {
                                font-size: 24px;
                                transition: .5s;                                
                                @media screen and (max-width: 767px) {
                                    font-size: 2.222rem;
                                    transition: .5s;
                                    @media screen and (max-width: 480px) {
                                        text-align: center;
                                        font-size: 1.5rem;
                                        font-weight: 600;
                                        padding: 0;
                                        margin: 0;
                                    }
                                }
                            }
                        }                        
                    }
                }
                div.content-unique-logo {
                    width: 100%;
                    transition: .5s;
                    @media screen and (max-width: 1355px) {
                        max-width: 100%;
                        transition: .5s;
                    }
                }
                ul.list-services-security {
                    padding-left: 55px;
                    transition: .5s;
                    @media screen and (max-width: 1575px) {
                        padding-left: 24px;
                    }
                    li.item-list {
                        text-transform: none;
                        color: #000;
                        font-size: 26px;
                        line-height: 35px;
                        font-weight: 500;
                        margin-bottom: 40px;
                        transition: .5s;
                        @media screen and (max-width: 1575px) {
                            font-size: 1.5vw;
                            margin-bottom: 15px;
                            @media screen and (max-width: 1250px) {
                                font-size: 1.3rem;
                                margin-bottom: 1.2rem;
                                @media screen and (max-width: 767px) {
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }
                }
                h4 {
                    color: #000;
                    font-size: 30px;
                    line-height: 45px;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    transition: .5s;
                    @media screen and (max-width: 1250px) {
                        font-size: 1.8rem;
                        line-height: 1.5rem;
                        padding-left: 10px;
                        @media screen and (max-width: 480px) {
                            text-align: center;
                            line-height: 25px;
                        }
                    }
                }
                p {
                    color: #000;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 35px;
                    margin-bottom: 37px;
                    padding-left: 20px;
                    width: 90%;
                    @media screen and (max-width: 1250px) {
                        font-size: 1.8rem;
                        margin-bottom: 1.2rem;
                        padding-left: 10px;
                        width: 100%;
                        @media screen and (max-width: 767px) {
                            font-size: 1.8rem;
                            width: 100%;
                            @media screen and (max-width: 767px) {
                                font-size: 1.7rem;
                                text-align: center;
                                padding: 0;
                            }
                        }
                    }
                }
            }            
        }        
    }
    div.container-fluid.part-1.second-container {
        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
        div.left-side.content-img.coloumn-img {           
            @media screen and (max-width: 991px) {
                width: 100%;
                padding: 0;
                margin: 0;
            }
        }
        div.right-side.content-text {
            @media screen and (max-width: 991px) {
                width: 100%!important;
                padding: 0;
                margin: 0;
            }
        }
    }

    section.contact-bottom-part-security {
        display: flex;
        justify-content: center;
        align-items: center;
        div.content-btn-paralaxed {            
            padding: 15px;
            height: 125px;
            display: flex;
            min-width: 300px;
            justify-content: center;
            background: #fbe903;
            align-items: center;
            width: 30vw;
            transition: .5s;
            @media screen and (max-width: 1575px) {
                height: 105px;
                padding: 10px;
                transition: .5s;
                @media screen and (max-width: 1250px) {
                    height: 95px;
                    padding: 10px;
                }
            }
            a {
                width: 100%;
                height: 100%;
                color: #000;
                font-size: 34px;                
                font-weight: 500;
                border: 4px solid #FFF;
                text-transform: uppercase;
                justify-content: center;
                align-items: center;
                display: flex;
                transition: .5s;
                @media screen and (max-width: 1575px) {
                    font-size: 1.5vw;
                    @media screen and (max-width: 1250px) {
                        font-size: 1.3rem;
                        @media screen and (max-width: 767px) {
                            font-size: 1.9rem;
                        }
                    }
                }
            }
        }
        div.content-btn-paralaxed:hover {
            background: #aca000;
            transition: .5s;
            a {
                background: #aca000;
                transition: .5s;
            }
        }
    }
}

main.coordonnees-page {
    section.section-text-img-coordonnees {
        padding: 0 15px;
        transition: .5s;
        div.container-fluid.part-1 {
            display: flex;
            justify-content: center;
            align-items: end;
            transition: .5s;
            @media screen and (max-width: 767px) {
                flex-direction: column;
            }
            div.left-side.content-text {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                flex-direction: column;
                text-align: right;
                padding: 75px 0;
                padding-bottom: 0;
                transition: .5s;
                @media screen and (max-width: 767px) {
                    transition: .5s;
                    width: 100%;
                    text-align: center;
                }
                h3 {
                    transition: .5s;
                    text-transform: none;
                    font-weight: 400;
                    font-size: 32px;
                    a {
                        color: #000;
                    }
                    small {
                        transition: .5s;
                        color: #000;
                        font-size: 22px;
                    }
                }
                h3.title-top {
                    width: 70%;
                    background: #fce903;
                    text-transform: uppercase;
                    margin-bottom: 25px;
                    padding: 15px;
                    font-weight: 400;
                    font-size: 32px;   
                    transition: .5s;
                    @media screen and (max-width: 1445px) {
                        font-size: 1.7rem;
                        transition: .5s;
                        @media screen and (max-width: 1145px) {
                            width: 100%;
                        }
                    }                 
                }
                h3.address-details {
                    margin-bottom: 25px;
                    transition: .5s;
                    @media screen and (max-width: 1445px) {
                        font-size: 1.6rem;
                    }
                }
                h3.fax,
                h3.phone {
                    transition: .5s;
                    a {
                        font-size: 35px;
                        transition: .5s;
                        @media screen and (max-width: 1445px) {
                            font-size: 1.9rem;
                        }
                    }
                }
                h3.email {
                    margin-top: 10px;
                }
                div.content-infos {
                    padding-right: 50px;
                    transition: .5s;
                    @media screen and (max-width: 767px) {
                        width: 100%;
                        padding-right: 0;
                    }
                }

                div.heures-ouverture {
                    width: 70%;
                    border-top: 3px solid #fce903;
                    border-bottom: 3px solid #fce903;   
                    margin-top: 35px;                 
                    padding-top: 25px;
                    margin-bottom: 35px;
                    padding-bottom: 25px;
                    padding-right: 50px;
                    transition: .5s;
                    @media screen and (max-width: 1145px) {
                        width: 100%;
                        padding-right: 0;
                    }
                    h3 {                        
                        font-size: 24px;
                        font-weight: 500;
                        text-transform: uppercase;
                        transition: .5s;
                        @media screen and (max-width: 1445px) {
                            font-size: 1.2rem;
                            @media screen and (max-width: 767px) {
                                font-size: 1.4rem;
                            }
                        }
                    }
                    h4 {
                        color: #000;
                        position: relative;                        
                        font-size: 22px;
                        top: 10px;
                        transition: .5s;
                        @media screen and (max-width: 1445px) {
                            font-size: 1.2rem;
                            @media screen and (max-width: 767px) {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }

                div.content-paragraph-contact {
                    width: 60%;
                    min-width: 565px;
                    padding-right: 50px;
                    transition: .5s;
                    @media screen and (max-width: 1145px) {
                        width: 100%;
                        min-width: 100%;
                        @media screen and (max-width: 767px) {
                            padding-right: 0;
                        }
                    }
                    h3 {
                        line-height: 45px;
                        transition: .5s;
                        @media screen and (max-width: 1445px) {
                            font-size: 1.5rem;
                            @media screen and (max-width: 991px) {
                                line-height: 32px;
                                @media screen and (max-width: 767px) {
                                    font-size: 1.7rem;
                                }
                            }
                        }
                    }

                    p {
                        color: #000;
                        font-weight: 400;
                        margin-top: 42px;
                        transition: .5s;
                        @media screen and (max-width: 1445px) {
                            font-size: 1.2rem;
                            transition: .5s;
                            @media screen and (max-width: 767px) {
                                font-size: 1.4rem;
                                margin-top: 25px;
                            }
                        }
                    }
                }
            }
            div.right-side.content-map {
                width: 50%;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
}

//////////// SECTION FORMULAIRE
div.section-form {
    background: #fce903;
    padding: 75px 10px;
}

section.section-last-img-text {
    display: flex;
    align-content: center;
    justify-content: center;
    div.container-fluid {
        display: flex;
        justify-content: center;
        align-items: center;
        //min-height: 588px;
        @media screen and (max-width: 900px) {
            flex-direction: column;
            transition: .5s;
        }
        div.left-side.content-text {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: flex-end;
            text-align: right;
            padding: 0 5vw;
            transition: .5s;
            @media screen and (max-width: 991px) {
                width: 65%;
                transition: .5s;
                @media screen and (max-width: 900px) {
                    width: 100%;
                    transition: .5s;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    padding: 35px 0;
                }
            }
            p.last-paragrph {
                color: #000;
                font-size: 33px;
                font-weight: 400;                
                min-width: 450px;
                width: 50%;
                transition: .5s;
                @media screen and (max-width: 1625px) {
                    font-size: 1.7rem;
                    transition: .5s;
                    @media screen and (max-width: 1199px) {
                        font-size: 1.5rem;
                        transition: .5s;
                        @media screen and (max-width: 900px) {
                            min-width: 100%;
                            padding: 10px;
                            transition: .5s;
                        }
                    }
                }
            }
            div.content-payement-mode {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    @media screen and (max-width: 991px) {
                        height: 50px;
                    }                    
                }
            }
        }
    }

    div.right-side.content-image {
        width: 50%;
        @media screen and (max-width: 900px) {
            width: 100%;
            transition: .5s;
        }
    }
}

div.content-img.slide-show-content {
    * {box-sizing: border-box;}
    body {font-family: Verdana, sans-serif;}
    .mySlides, 
    .mySlides_2,
    .mySlides_2 {display: none;}
    img {vertical-align: middle;}

    /* Slideshow container */
    .slideshow-container {
        max-width: 1000px;
        position: relative;
        margin: auto;
        background: #000;
    }

    /* Caption text */
    .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
    }

    /* Number text (1/3 etc) */
    .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
    }

    /* The dots/bullets/indicators */
    .dot {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    }

    .active {
    background-color: #717171;
    }

    /* Fading animation */
    .fade {
        background: #000;
        -webkit-animation-name: fade;
        -webkit-animation-duration: 1.5s;
        animation-name: fade;
        animation-duration: 1.5s;
    }

    @-webkit-keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
    }

    @keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
    }

    /* On smaller screens, decrease text size */
    @media only screen and (max-width: 300px) {
    .text {font-size: 11px}
    }
}

div.second-slide-thing {
    opacity: 0;
    position: absolute;
    top: 0px;
}

div.firtd-slide-thing {
    opacity: 0;
    position: absolute;
    top: 0px;
}

.carousel-control.right {
    background-image: -webkit-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
    background-image: -o-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
    background-image: linear-gradient(to right,rgba(0,0,0,.0001) 0,rgba(0, 0, 0, 0.05) 100%);
}

div.carousel-inner {
    border-radius: 3px;
}

a.left.carousel-control,
a.right.carousel-control {
    background: rgba(200, 200, 200, 0.12941176470588237);
}

span.glyphicon.glyphicon-chevron-right,
span.glyphicon.glyphicon-chevron-left {
    color: #333;
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/soumission';

/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* X PAGE
*/

.pageX {
    section.header {
        transition: all .2s linear;
        &:hover {
            transition: all .2s linear;
        }
    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-xs-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {
        h2 {
            font-size: 3em;
        }
    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
