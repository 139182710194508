// *************************************************************
// Ce fichier load les fonts et
// définit les classes de font du theme
//

// *************************************************************
// >> Choisir les fonts à loader ici (décommenter celles utilisées ou ajouter des nouvelles fonts)

//***** LOCAL FONTS dans folder /fonts/ *****//
// généré par https://google-webfonts-helper.herokuapp.com/fonts

/* fira-sans-100 - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/fira-sans-condensedfira-sans-v8-latin-100.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Thin'), local('FiraSans-Thin'),
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-100.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-100.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
  /* fira-sans-200 - latin */
  @font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/fira-sans-condensedfira-sans-v8-latin-200.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans ExtraLight'), local('FiraSans-ExtraLight'),
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-200.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-200.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
  /* fira-sans-regular - latin */
  @font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/fira-sans-condensedfira-sans-v8-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Regular'), local('FiraSans-Regular'),
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
  /* fira-sans-500 - latin */
  @font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/fira-sans-condensedfira-sans-v8-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Medium'), local('FiraSans-Medium'),
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-500.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
  /* fira-sans-600 - latin */
  @font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/fira-sans-condensedfira-sans-v8-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans SemiBold'), local('FiraSans-SemiBold'),
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-600.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
  /* fira-sans-700 - latin */
  @font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/fira-sans-condensedfira-sans-v8-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Bold'), local('FiraSans-Bold'),
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-700.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
  /* fira-sans-800 - latin */
  @font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/fira-sans-condensedfira-sans-v8-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans ExtraBold'), local('FiraSans-ExtraBold'),
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-800.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-800.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
  /* fira-sans-900 - latin */
  @font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/fira-sans-condensedfira-sans-v8-latin-900.eot'); /* IE9 Compat Modes */
    src: local('Fira Sans Black'), local('FiraSans-Black'),
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-900.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-condensedfira-sans-v8-latin-900.svg#FiraSans') format('svg'); /* Legacy iOS */
  }

/* libre-baskerville-regular - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Libre Baskerville'), local('LibreBaskerville-Regular'),
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}
/* libre-baskerville-700 - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'),
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}

//***** GOOGLE HOSTED *****//
//***** SANS SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,700i,900');
// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i');
// @import url('https://fonts.googleapis.com/css?family=Oswald');
// @import url('https://fonts.googleapis.com/css?family=Fira+Sans');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans');
// @import url('https://fonts.googleapis.com/css?family=Dosis:300,400,700');

//***** SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto+Slab');
// @import url('https://fonts.googleapis.com/css?family=Alegreya');


// >> C'est ici qu'on met les font-family à utiliser
$font-family-1: "Fira Sans Condensed", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$font-family-2: "Libre Baskerville", Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
// $font-family-3: "Roboto Condensed", arial, Helvetica;

// *************************************************************
// >> Les CLASSES de fonts qui sont utilisées dans le theme.
//    Avec les fonts system par défaut

// fira-sans
.font-1 { font-family: $font-family-1; font-weight: 400; }
.font-1-light { @extend .font-1; font-weight: 300; }
.font-1-medium { @extend .font-1; font-weight: 500; }
.font-1-bold { @extend .font-1; font-weight: 700; }
// Libre Baskerville
.font-2 { font-family: $font-family-2; font-weight: 400; }
.font-2-bold { @extend .font-2; font-weight: 700; }

// MAIN FONTS
.font-main {
    @extend .font-1-bold;
    @extend .text-color;
    font-size: $font-size-24;
    @media screen and (max-width: $size-xs-max) {
        font-size: $font-size-16;
    }
}
h3 {
    @extend .font-1-bold;
    @extend .text-color-header;
    text-transform: uppercase;
    font-size: $font-size-24;
    @media screen and (max-width: $size-xs-max) {
        font-size: $font-size-18;
    }
}

// NAV FONTS
// font pour les items de premier niveau
.font-nav-items {
    @extend .font-1-bold;
    font-size: $font-size-16;
    line-height: 1;
    text-transform: uppercase;
}
// font pour les entete dans les dropdowns
.font-nav-drop-header {
    @extend .font-2-bold;
    font-size: $font-size-24;
}
// font pour les items dans les dropdowns
.font-nav-drop-items {
    @extend .font-1-bold;
    font-size: $font-size-16;
    line-height: 1;
}
.font-nav-phone {
    @extend .font-2-bold;
    font-size: $font-size-16;
}

// si le logo est du texte
.font-nav-logo {
    @extend .font-2;
    font-size: $font-size-28;
    @media screen and (max-width: $size-sm-max){
        font-size: $font-size-20;
    }
    @media screen and (max-width: $size-xs-max){
        font-size: $font-size-14;
    }
}

// FOOTER FONTS

.font-footer {
    @extend .font-1;
    font-size: $font-size-14;
}