.section-text-image {    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    div.items-start {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: initial;

        div.image-side {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: initial;
        }

        div.text-side {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: initial;
            span.buld-normal {
                font-weight: 700;
            }
            div.container-fluid {
                p {
                    font-size: 20px;
                    color: #151515;
                    font-weight: 400;
                    width: 75%;
                }
            }
            div.middle-side-text {
                //background: #fbe903;
                background-image: linear-gradient(#fbe903, #ccbd02);
                padding: 5px;
                width: 100%;
                margin-top: 35px;
                margin-bottom: 35px;
                div.content-middle {
                    margin: 10px;
                    border: 2px solid #FFF;
                    padding: 25px 40px;
                    h2 {
                        color: #000;
                        line-height: 75px;
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 42px;
                    }
                    h3 {
                        color: #000;
                        line-height: 30px;
                        text-transform: uppercase;
                        font-weight: 400;
                        font-size: 24px;
                    }
                }
            }
        }
    }

    a.telephone {
        color: #000;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 42px;
    }

    .image {
        padding: 0;
        img {
            float: right;
        }
    }
    .text {
        padding-top: 7vh;
        p {
            font-size: 20px;
        }
        blockquote, .cite {
            text-align: right;
            @extend .font-2;
            font-size: 1.2vw;
            font-size: 24px;
        }
        blockquote {
            line-height: 1.7;
            padding: 2vh 0 2vh 20%;
            border-left: none;
            border-top: 1px solid $color-white;
            border-bottom: 1px solid $color-white;
            color: $color-primary;
            strong {
                @extend .font-1;
                text-transform: uppercase;
                font-weight: 300;
                color: $color-white;
            }
        }
        .cite {
            color: $color-white;
            line-height: 1.1;
            margin: 2vh 0 7vh 0;
            small {
                font-size: 13px;
                @extend .font-1;
                font-weight: 300;
            }
        }
        blockquote + p {
            margin-top: 5vh;
        }
        p {
            margin: 0 0 5vh 0;
            text-align: justify;
        }
    }
    @media screen and (max-width: $size-sm-max){

    }
}

@media screen and (max-width: 991px) {
    .section-text-image {
        div.items-start {
            flex-direction: column;
            div.image-side  {
                width: 100%;
            }
            div.text-side {
                width: 100%;
                padding-left: 25px;
                padding-right: 25px;
                div.container-fluid {
                    p {
                        width: 100%;
                    }
                }
            }
        }
    }
}