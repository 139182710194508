.slideshow-wrap {
    position: relative;
    #layerslider { display: block; 
        div.ls-inner {
            div.ls-slide {
                img {
                    margin-top: 0!important;
                    margin-bottom: 0!important;
                }
            }
        }
    }

    .ls-bottom-nav-wrapper {
        display: none;
    }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        font-size: 1.5vw;
        text-align: center;
        padding-bottom:0.5em;
    }
    .title {
        @extend .font-2;
        text-transform: uppercase;
        color: $color-primary;
        font-size: 3vw;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }
    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: 11px;
            border-width: 1px;
            padding: 5px;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        .title {
            font-size: 26px;
        }
        .subtitle {
            font-size: 16px;
        }

    }
}

div.caption.container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    height: 50vw;
    div.row {
        div.col-xs-12 {
            div.content-logo {                
                display: flex;
                align-items: center;
                justify-content: center;
            }
            h3 {
                color: #fbe903;
                margin-top: 15px;
                font-weight: 500;
                font-size: 35px;
                letter-spacing: 8px;
                text-shadow: 0px -1px 8px rgb(0, 0, 0);
                text-align: center;
            }

            h4 {
                color: #FFF;
                margin-top: 15px;
                font-weight: 500;
                font-size: 30px;
                text-align: center;
                text-transform: uppercase;
                border-top: 2px solid #FFF;
                padding-top: 25px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    div.caption.container {
        div.row {
            div.col-xs-12 {
                div.content-logo {
                    margin-top: 12vw;
                    img {
                        width: 50vw;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 678px) {
    div.caption.container {
        div.row {
            div.col-xs-12 {
                div.content-logo {
                    margin-top: 12vw;
                    img {
                        width: 50vw;
                    }
                }
                h3 {
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    div.caption.container {
        div.row {
            div.col-xs-12 {
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                height: 100vw;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                position: absolute;
                div.content-logo {
                    margin-top: 35vw;
                    img {                        
                        width: auto;
                        height: 35px;
                    }
                }
                h3 {
                    font-size: 20px;
                }
            }
        }
    }
}